import {
  AddTagT,
  AgentStatusT,
  AgentT,
  AllSessionMock,
  AttrT,
  BotsLA,
  SessionResT,
  Sessions,
  StatusM,
  UserInteractionT,
  currentTab,
  eventList,
  eventType,
  initialSessionData,
  mockAgent,
  mockTagList,
  mockUserData,
  tabStatus,
  Customdate,
} from './LiveagentMockFile';
import {
  AgentAttachment,
  AgentMessageEvent,
  AgentTransferT,
  CommentEvent,
  FeedbackSend,
  MarkdownMdObject,
  ReceiveEvents,
  SendEvents,
  SessionEvents,
} from './LiveAgentTypes';
import {
  deleteJson,
  getJson,
  loginApiURL,
  postJson,
  putJson,
  watsonLogsApiURL,
} from '@/common/utils/api-utils';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AssistantT } from '@/Assistant/Construction/kind';
import Axios from 'axios';
import { MOCK_USER_API } from '@/common/utils/utils';
import { Markdown } from './MarkDown';
import React from 'react';
import { ReadyState } from 'react-use-websocket';
import { UserApi } from '@/DialogEditor/Canvas/Components/GetStarted';
import { getAssistants } from '@/Assistant/Mechanisms/action';
import { statusList } from './LiveAgentMenu';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { chatAssignmentT, ChatStausT } from './dropDown';
import { User } from '@/ManageUsers/types.user';
// true to make the API work in local
export const localAPI = false;

const mockData = false;
export const mockchat = false;
export const mockChatchat = false;

const eventMock = false;
export const getBaseAPI = () => {
  return localAPI
    ? 'https://dev-assistant.workativ.ai'
    : window.location.origin;
};

export const getBaseDomainHost = () => {
  return localAPI ? 'dev-assistant.workativ.ai' : window.location.host;
};

export const fetchAllSession = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    selectedBotID,
    sortBy,
    pageNumber,
    closeLoading,
    userID,
  ] = queryKey;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';
  const data: SessionResT = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&page=${pageNumber}&agent_id=${userID}`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&team_id=${selectedBotID}&page=${pageNumber}&agent_id=${userID}`
        )
    : AllSessionMock;
  return data;
};

export const useAllSession = (
  workspace: string,
  selectedBotID: string,
  sortBy: string,
  pageNumber: number,
  closeLoading: () => void,
  userID: number | undefined
) => {
  console.log(sortBy, 'sortall');
  const {
    data: AllData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
    error,
  } = useQuery(
    [
      'allSession',
      workspace,
      selectedBotID,
      sortBy,
      pageNumber,
      closeLoading,
      userID,
    ],
    fetchAllSession,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      onSuccess: closeLoading,
      enabled: !!userID,
    }
  );
  return {
    AllData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isAllSessionsFetched: isFetched,
    isSessionError: error,
  };
};

export const fetchSessionStatus = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    sesStatus,
    selectedBotID,
    sortBy,
    pageNumber,
    closeLoading,
    userID,
  ] = queryKey;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';

  const data = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=Queued&page=${pageNumber}&agent_id=${userID}`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=Queued&team_id=${selectedBotID}&page=${pageNumber}&agent_id=${userID}`
        )
    : AllSessionMock;
  // .sessions.result.filter(
  //     (data) => data.session_status === sesStatus
  //   );
  return data;
};

export const useQueusStatus = (
  workspace: string,
  sesStatus: string,
  selectedBotID: string,
  sortBy: string,
  pageNumber: number,
  closeLoading: () => void,
  userID: number | undefined
) => {
  const {
    data: queueSessionData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isError,
  } = useQuery(
    [
      'queueStatus',
      workspace,
      sesStatus,
      selectedBotID,
      sortBy,
      pageNumber,
      closeLoading,
      userID
    ],
    fetchSessionStatus,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      onSuccess: closeLoading,
      enabled: !!userID,
    }
  );
  return {
    queueSessionData,
    sessionRetry,
    dataUpdatedAt,
    isSessionError: isError,
    isRefetching,
  };
};

export const fetchOpenStatus = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    sesStatus,
    selectedBotID,
    sortBy,
    pageNumber,
    closeLoading,
    userID
  ] = queryKey;
  const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';
  const data = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=InProgress&page=${pageNumber}&agent_id=${userID}`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=InProgress&team_id=${selectedBotID}&page=${pageNumber}&agent_id=${userID}`
        )
    : AllSessionMock.sessions.result.filter(
        (data) => data.session_status === sesStatus
      );
  return data;
};

export const useOpenStatus = (
  workspace: string,
  sesStatus: string,
  selectedBotID: string,
  sortBy: string,
  pageNumber: number,
  closeLoading: () => void,
  userID: number | undefined
) => {
  const {
    data: openSessionData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching,
    isError,
  } = useQuery(
    [
      'openStatus',
      workspace,
      sesStatus,
      selectedBotID,
      sortBy,
      pageNumber,
      closeLoading,
      userID
    ],
    fetchOpenStatus,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      onSuccess: closeLoading,
      enabled: !!userID,
    }
  );

  return {
    openSessionData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    openFetching: isFetching,
    isSessionError: isError,
  };
};
export const fetchMentionStatus = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    sesStatus,
    selectedBotID,
    sortBy,
    userID,
    pageNumber,
    closeLoading,
  ] = queryKey;
  const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';
  const data = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&agent_id=${userID}&page=${pageNumber}&mention=true`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&team_id=${selectedBotID}&agent_id=${userID}&page=${pageNumber}&mention=true`
        )
    : AllSessionMock.sessions.result.filter(
        (data) => data.session_status === sesStatus
      );
  return !mockData ? data : data;
};

export const useMentionStatus = (
  workspace: string,
  sesStatus: string,
  selectedBotID: string,
  sortBy: string,
  userID: number | undefined,
  pageNumber: number,
  closeLoading: () => void
) => {
  const {
    data: mentionSessionData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching,
    isError,
  } = useQuery(
    [
      'mentionStatus',
      workspace,
      sesStatus,
      selectedBotID,
      sortBy,
      userID,
      pageNumber,
      closeLoading,
    ],
    fetchMentionStatus,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      enabled: !!userID,
      onSuccess: closeLoading,
    }
  );

  return {
    mentionSessionData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    openFetching: isFetching,
    isSessionError: isError,
  };
};

export const fetchClosedStatus = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    sesStatus,
    selectedBotID,
    sortBy,
    pageNumber,
    closeLoading,
    userID
  ] = queryKey;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';

  const data = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=Closed&page=${pageNumber}&agent_id=${userID}`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=Closed&team_id=${selectedBotID}&page=${pageNumber}&agent_id=${userID}`
        )
    : AllSessionMock.sessions.result.filter(
        (data) => data.session_status === sesStatus
      );
  return data;
};

export const useClosedSessions = (
  workspace: string,
  sesStatus: string,
  selectedBotID: string,
  sortBy: string,
  pageNumber: number,
  closeLoading: () => void,
  userID: number | undefined,
) => {
  const {
    data: closedSessionData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isError,
  } = useQuery(
    [
      'closedStatus',
      workspace,
      sesStatus,
      selectedBotID,
      sortBy,
      pageNumber,
      closeLoading,
      userID
    ],
    fetchClosedStatus,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      onSuccess: closeLoading,
      enabled: !!userID,
    }
  );
  return {
    closedSessionData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isSessionError: isError,
  };
};

export const fetchMYSession = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    userID,
    selectedBotID,
    sortBy,
    pageNumber,
    closeLoading,
  ] = queryKey;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';

  const data = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=InProgress&agent_id=${userID}&page=${pageNumber}&my_chat=true`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=InProgress&agent_id=${userID}&team_id=${selectedBotID}&page=${pageNumber}&my_chat=true`
        )
    : AllSessionMock;
  return !mockData
    ? data
    : data.sessions.result.filter(
        (data: Sessions) =>
          data.assigned_agent?.id === userID &&
          data.session_status === 'InProgress'
      );
};

export const useMySessions = (
  workspace: string,
  userID: number | undefined,
  selectedBotID: string,
  sortBy: string,
  pageNumber: number,
  closeLoading: () => void
) => {
  const {
    data: mySessionData,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isError,
  } = useQuery(
    [
      'myStatus',
      workspace,
      userID,
      selectedBotID,
      sortBy,
      pageNumber,
      closeLoading,
    ],
    fetchMYSession,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      enabled: !!userID,
      onSuccess: closeLoading,
    }
  );
  return {
    mySessionData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isSessionError: isError,
  };
};

export const fetchTrashedStatus = async ({ queryKey }: any) => {
  const [
    _,
    workspace,
    sesStatus,
    selectedBotID,
    sortBy,
    pageNumber,
    closeLoading,
    userID
  ] = queryKey;
  const sort = sortBy === 'Newest' ? 'Desc' : 'Asc';

  const data = !mockData
    ? selectedBotID === 'Inbox'
      ? await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=Trash&page=${pageNumber}&agent_id=${userID}`
        )
      : await getJson(
          `${getBaseAPI()}/liveagent/${workspace}/v1/session?sort_by=${sort}&status=Trash&team_id=${selectedBotID}&page=${pageNumber}&agent_id=${userID}`
        )
    : AllSessionMock.sessions.result.filter(
        (data) => data.session_status === sesStatus
      );
  return data;
};

export const useTrashedStatus = (
  workspace: string,
  sesStatus: string,
  selectedBotID: string,
  sortBy: string,
  pageNumber: number,
  closeLoading: () => void,
  userID: number | undefined,
) => {
  const {
    data: TrashedSessionData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isError,
  } = useQuery(
    [
      'trashStatus',
      workspace,
      sesStatus,
      selectedBotID,
      sortBy,
      pageNumber,
      closeLoading,
      userID
    ],
    fetchTrashedStatus,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      onSuccess: closeLoading,
      enabled: !!userID,
    }
  );

  return {
    TrashedSessionData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isSessionError: isError,
  };
};

export const useUpdateStatus = (
  queryFn: () => tabStatus,
  errorfn: () => void,
  successfn: () => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: statusMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (status: StatusM) =>
      Axios.patch(
        `${getBaseAPI()}/liveagent/${status.workspace}/v1/session/status`,
        status.status
      ),
    onSuccess: () => {
      const value = queryFn();
      queryClient.invalidateQueries(value);
      queryClient.invalidateQueries('closedStatus');
      queryClient.invalidateQueries('allSession');
      successfn();
    },
    onError: () => {
      errorfn();
    },
  });
  return {
    statusMutate,
    isError,
    isStatusMutateLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};

export const useMultiDelete = (
  queryFn: () => tabStatus,
  errorfn: () => void,
  successfn: () => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: multiDeleteMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (status: StatusM) =>
      Axios.patch(
        `${getBaseAPI()}/liveagent/${status.workspace}/v1/session/status`,
        status.status
      ),
    onSuccess: () => {
      const value = queryFn();
      queryClient.invalidateQueries(value);
      queryClient.invalidateQueries('closedStatus');
      queryClient.invalidateQueries('trashStatus');
      queryClient.invalidateQueries('allSession');
      queryClient.invalidateQueries('singleStatus');
      successfn();
    },
    onError: () => {
      errorfn();
    },
  });
  return {
    multiDeleteMutate,
    isError,
    isStatusMutateLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};
export const useDeleteSession = (
  errorfn: () => void,
  successfn: () => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: DeleteSession,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (status: StatusM) =>
      deleteJson(
        `${getBaseAPI()}/liveagent/${status.workspace}/v1/session/${
          status.sessionID
        }`
      )(),
    onSuccess: () => {
      queryClient.invalidateQueries('trashStatus');
      successfn();
    },
    onError: () => {
      errorfn();
    },
  });
  return {
    DeleteSession,
    isError,
    isDeleteSessionLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};
const getTime = () => {
  // This implementation uses Luxon: https://moment.github.io/luxon/
  // return DateTime.local();

  // You can also use moment: https://momentjs.com
  // return moment();

  // Or just use native Date objects (in general, not a good move)
  return new Date();

  // Or just use unix epoch timestamps (integers, no timezones)
  // return (new Date()).getTime();
};
export const useCurrentDate = () => {
  const [currentTime, setCurrentTime] = useState<Date>(getTime());
  // useEffect(() => {
  //   setInterval(() => {
  //     setCurrentTime(getTime());
  //   }, 60000);
  // })

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(() => setCurrentTime(getTime()), 1000);

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  }, []);
  return currentTime;
};
export type messageT = {
  type: string;
  msg: string;
  user: string;
};

export interface WebSocketContextT {
  readyState: ReadyState;
  message: SessionEvents;
  handleClickSendMessage: (
    sessionID: string,
    msg: Markdown,
    workspace: string
  ) => void;
  handleCommentSetting: (msg: MarkdownMdObject, sessionID: string) => void;
  handleAttachment: (
    sessionID: string,
    msg: Markdown,
    workspace: string,
    fileUrl: any
  ) => void;
  transferAgent: (
    newAgentID: string,
    // newAgentuuid: string,
    agentId: string,
    sessionId: string
  ) => void;
}
export const WebSocketContext = React.createContext<WebSocketContextT | null>(
  null
);

export interface TipTapContextT {
  removeFiles: (index: number) => void;
  attachmentFiles: string[];
}

export const TipTapContext = React.createContext<TipTapContextT | null>(null);

export interface messageArrayT {
  messageHistory: SessionEvents[] | [];
}
export const MessageArray = React.createContext<messageArrayT>({
  messageHistory: [],
});

const findKeyAndValue = (obj: Markdown, name: string, val: string) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const result: any = findKeyAndValue(obj[key], name, val);
      if (result) {
        return result;
      }
    } else if (key === name && obj[key] === val) {
      return { key, value: obj[key] };
    }
  }
  return null;
};

const findMentionID = (obj: Markdown, name: string) => {
  const valuesArray: string[] = [];

  const search = (obj: Markdown) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        search(obj[key]);
      } else if (key === name) {
        valuesArray.push(obj[key]);
      }
    }
  };

  search(obj);
  return valuesArray;
};

export const useConnectWebSocket: any = (
  workspace: string,
  queryFn: () => tabStatus
) => {
  const currentTime: Date = new Date();
  const value = queryFn();
  const { user } = useUserData();
  // const [messageHistory, setMessageHistory] = useState<SessionEvents[]>([]);
  const intialMsg: SessionEvents = {
    id: '',
    time: '',
    event: {},
    data: {},
  };
  const [message, setMessage] = useState<SessionEvents>(intialMsg);
  // const [msgstring, setMsgString] = useState<string>('');
  const { sendMessage, sendJsonMessage, lastMessage, readyState } =
    useWebSocket(
      `wss://${getBaseDomainHost()}/liveagent/${workspace}/${user?.id}/ws`,
      // 'dev-assistant.workativ.ai:8300/ws',
      // `wss://${getBaseDomainHost()}/la-ws/${workspace}/${user?.id}/ws`,
      // `ws://manjula-workativ.ngrok.io/${workspace}/${user?.id}/ws`,
      {
        onOpen: () => {
          // postJson(
          //   // `https://manjula-workativ.ngrok.io/${workspace}/join_room`
          //   `${getBaseAPI()}/la-ws/${workspace}/join_room`
          // )({
          //   channel_id: user?.id.toString(),
          //   room_id: workspace,
          // });
          console.log('WebSocket connection established.');
        },
        onError: (err) => {
          console.log(err, 'websocket error');
        },
        // onClose:()=>{
        //   setTimeout(useConnectWebSocket, 5000)
        // },
        shouldReconnect: (closeEvent) => true,
      }
    );

  useEffect(() => {
    const interval = setInterval(() => {
      if (readyState === ReadyState.OPEN) {
        sendJsonMessage({ event_type: "ping" });
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [readyState]);

  const feedBackmsg = {
    md_type: 'md_normal',
    value: 'Collect user feedback',
  };

  // const feedBackmsgwithnew = {
  //   md_type: 'md_normal',
  //   value: 'Collect user feedback\n',
  // };

  const transferAgent = (
    newAgentID: number,
    agentId: number,
    sessionId: string,
    teamId: string
  ) => {
    console.log(newAgentID, agentId, sessionId, teamId, 'transferAgent');
    const AgentTransfer: AgentTransferT = {
      id: uuidv4(),
      time: currentTime,
      event_type: SendEvents.AgentTransfer,
      workspace: workspace,
      data: {
        id: uuidv4(),
        session_id: sessionId,
        current_agent_id: agentId,
        transferred_agent_id: newAgentID,
        team_id: teamId,
        message_time: currentTime,
        delivery_status: 1,
        deleted: 0,
      },
    };
    sendJsonMessage(AgentTransfer);
  };

  const handleAttachment = (
    sessionID: string,
    msg: Markdown,
    workspace: string,
    form: any
  ) => {
    const AgentAttachment: AgentAttachment = {
      id: uuidv4(),
      time: currentTime,
      event_type: SendEvents.AgentAttachment,
      workspace: workspace,
      data: {
        id: uuidv4(),
        session_id: sessionID,
        agent_id: user?.id.toString(),
        message: msg,
        files: form, // will change
        message_time: currentTime,
        delivery_status: 1,
        deleted: 0,
      },
    };
    sendJsonMessage(AgentAttachment);
  };

  const handleClickSendMessage = (
    sessionID: string,
    msg: Markdown,
    workspace: string,
    sessionData: Sessions
  ) => {
    if (_.isEqual(msg, feedBackmsg)) {
      const AgentFeedback: FeedbackSend = {
        id: uuidv4(),
        time: currentTime,
        event_type: ReceiveEvents.FeedbackView,
        workspace: workspace,
        data: {
          id: uuidv4(),
          session_id: sessionID,
          agent_id: user?.id.toString(),
          message_time: currentTime,
          delivery_status: 1,
          deleted: 0,
        },
      };
      // setMessage(value)
      sendJsonMessage(AgentFeedback);
    } else {
      const AgentMessage: AgentMessageEvent = {
        id: uuidv4(),
        time: currentTime,
        event_type: SendEvents.AgentMessage,
        workspace: workspace,
        data: {
          id: uuidv4(),
          session_id: sessionID,
          agent_id: user?.id.toString(),
          message: msg,
          // Attachments: AttachmentT // will change
          message_time: currentTime,
          delivery_status: 1,
          deleted: 0,
        },
      };
      sendJsonMessage(AgentMessage);
      // setMessage(AgentMessage);
    }
    // } else {
    // }
  };
  // sendJsonMessage(message);
  // setMessage(intialMsg);
  // };
  const queryClient = useQueryClient();
  useEffect(() => {
    if (lastMessage !== null) {
      const parsedValue = JSON.parse(lastMessage.data);
      const keys = Object.keys(parsedValue);
      console.log(
        parsedValue.event_type === 'connect',
        parsedValue.event_type,
        parsedValue.data,
        parsedValue,
        'connectEvent'
      );
      if (
        parsedValue.event_type === 'agent_transfer'
        // parsedValue.event_type === 'connect'
      ) {
        console.log('connectEvent');
        queryClient.invalidateQueries(value);
        queryClient.invalidateQueries('allSession');
        queryClient.invalidateQueries('myStatus');
        queryClient.invalidateQueries('openStatus');
        queryClient.invalidateQueries('singleStatus');
        queryClient.invalidateQueries('agentList');
        queryClient.invalidateQueries('closedStatus');
      }
      // if(keys.length == 1 && parsedValue[keys[0]]?.event_type === "Connect"){
      //   <LiveAgentNotification msg={JSON.parse(lastMessage.data)}/>
      // }
      // setMessageHistory([...messageHistory, JSON.parse(lastMessage.data)]);
      setMessage(JSON.parse(lastMessage.data));
    }
  }, [lastMessage]);

  const handleCommentSetting = (msg: MarkdownMdObject, sessionID: string) => {
    const checkMention = findKeyAndValue(msg, 'md_type', 'md_mention');
    const AgentComment: CommentEvent = {
      id: uuidv4(),
      time: currentTime,
      event_type: ReceiveEvents.Comment,
      workspace: workspace,
      data: {
        id: uuidv4(),
        session_id: sessionID,
        message: msg,
        agent_id: user?.id,
        delivery_status: 1,
        message_time: currentTime,
      },
    };
    const MentionIds = findMentionID(msg, 'id');
    const AgentMention: CommentEvent = {
      id: uuidv4(),
      time: currentTime,
      event_type: ReceiveEvents.Mention,
      workspace: workspace,
      data: {
        id: uuidv4(),
        session_id: sessionID,
        message: msg,
        agent_id: user?.id,
        mention: MentionIds,
        delivery_status: 1,
        message_time: currentTime,
      },
    };
    sendJsonMessage(checkMention === null ? AgentComment : AgentMention);
    // setMessage(checkMention === null ? AgentComment : AgentMention);
  };
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  return {
    readyState,
    message,
    handleClickSendMessage,
    handleCommentSetting,
    handleAttachment,
    transferAgent,
  };
};
// export const userData = async()=>{
//   // const [user,setUser] =useState<UserApi>({
//   //   phone_number: {},
//   //   id: 0,
//   //   first_name: '',
//   //   last_name: '',
//   //   email: '',
//   //   org: '',
//   //   role: '',
//   //   country: '',
//   // });
//   if (MOCK_USER_API) {
//     const userDetails:UserApi = {
//       phone_number: {
//         id: 3,
//         mobileNo: '',
//         verified: true,
//         valid: true,
//         createdAt: '2020-03-27T13:43:49.000Z',
//         updatedAt: '2020-03-27T13:43:49.000Z',
//         code: '91',
//         userId: 36,
//       },
//       id: 36,
//       first_name: 'Aruna',
//       last_name: 'Devi',
//       email: 'arunadevi@workativ.com',
//       org: 'workativ',
//       role: 'Developer',
//       country: 'IN',
//     };
//     return userDetails;
//   } else {
//     const userData = await getJson(loginApiURL('/user_info')).then((data:UserApi)=> {return data})
//      return userData
//   }
// }

export const tabUpdate = (tabLoc: string): currentTab => {
  switch (tabLoc) {
    case 'all':
      return 'All';
    case 'queued-sessions':
      return 'Queue';
    case 'my-session':
      return 'My';
    case 'open-sessions':
      return 'Open';
    case 'closed-sessions':
      return 'Closed';
    case 'trash':
      return 'Trash';
    case 'mention-sessions':
      return 'Mention';
  }
  return 'All';
};

export const fetchUserData = async () => {
  const data: UserApi = MOCK_USER_API
    ? {
        phone_number: {
          id: 135,
          mobileNo: '7550242010',
          verified: false,
          valid: true,
          createdAt: '2023-03-16T08:34:55.000Z',
          updatedAt: '2023-03-16T08:34:55.000Z',
          code: '1',
          userId: 235,
        },
        id: 36,
        first_name: 'Arunadevi',
        last_name: 'Rathinakumar',
        email: 'arunadevi.r@workativ.com',
        org: 'Workativ',
        role: null,
        country: null,
        verified: true,
      }
    : await getJson(loginApiURL('/user_info'));

  return data;
};

export const useUserData = () => {
  const {
    data,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    status,
    dataUpdatedAt,
    isRefetching,
  } = useQuery(['user'], fetchUserData, {
    staleTime: Infinity,
  });
  return { user: data, sessionRetry, dataUpdatedAt, isRefetching };
};

export const conversation = (
  tabId: string,
  sessionData: any,
  idAvailable: boolean
): Sessions => {
  if (!statusList.includes(tabId) && sessionData && idAvailable) {
    const data =
      sessionData &&
      sessionData.filter((ses: Sessions) => ses.chat_id == parseInt(tabId))[0];
    return data;
  } else {
    return sessionData && sessionData.length > 0
      ? sessionData[0]
      : initialSessionData;
  }
};

export const fetchAgentList = async ({ queryKey }: any) => {
  const [_, workspace, sesStatus, selectedBotID] = queryKey;
  const data: AgentT[] = !mockData
    ? await getJson(`${getBaseAPI()}/liveagent/${workspace}/v1/agent`)
    : mockAgent;
  return data;
};

export const useAgentList = (workspace: string) => {
  const {
    data: agentList,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching,
  } = useQuery(['agentList', workspace], fetchAgentList, {
    // refetchInterval: 30000,
    // staleTime: Infinity,
  });

  return {
    agentList,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isAgentFetching: isFetching,
  };
};

export const fetchSingleStatus = async ({ queryKey }: any) => {
  const [_, workspace, sessionID] = queryKey;
  // const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID
  const data: Sessions = !mockData
    ? await getJson(
        `${getBaseAPI()}/liveagent/${workspace}/v1/session/${sessionID}`
      )
    : AllSessionMock.sessions.result
        .filter((data) => data.id === sessionID)
        .pop();
  return data;
};

export const useSingleStatus = (workspace: string, sessionID: string) => {
  const {
    data: singleSessionData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(['singleStatus', workspace, sessionID], fetchSingleStatus, {
    staleTime: 30000,
  });

  return {
    singleSessionData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isSessionFetched: isFetched,
  };
};

export const useAddTag = (error: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: addTag,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (tagValue: AddTagT) =>
      postJson(
        `${getBaseAPI()}/liveagent/${tagValue.workspace}/v1/session/${
          tagValue.sessionID
        }/tag`
      )(tagValue.tagInfo),
    onSuccess: () => {
      queryClient.invalidateQueries('singleStatus');
      queryClient.invalidateQueries('tagList');
    },
    onError: () => {
      error();
    },
  });
  return {
    addTag,
    isError,
    isAddTagLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};

export const useDeleteTag = (error: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: deleteTag,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (tagValue: AddTagT) =>
      deleteJson(
        `${getBaseAPI()}/liveagent/${tagValue.workspace}/v1/session/${
          tagValue.sessionID
        }/tag/${tagValue.tagInfo.name}`
      )({}),
    onSuccess: () => {
      queryClient.invalidateQueries('singleStatus');
    },
    onError: () => {
      error();
    },
  });
  return {
    deleteTag,
    isError,
    isDeleteTagLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};

export const fetchAllTags = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data = !mockData
    ? await getJson(`${getBaseAPI()}/liveagent/${workspace}/v1/tags`)
    : mockTagList;
  return data;
};

export const useAllTags = (workspace: string) => {
  const {
    data: tagData,
    refetch: tagRetry,
    dataUpdatedAt,
    isFetched,
  } = useQuery(['myStatus', workspace], fetchAllTags, {
    refetchInterval: 30000,
    staleTime: 30000,
  });
  return { tagData, tagRetry, dataUpdatedAt, istagFetched: isFetched };
};

export const useAgentStatus = () => {
  const queryClient = useQueryClient();
  const {
    mutate: agentStatus,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (agentUpdate: AgentStatusT) =>
      Axios.patch(
        `${getBaseAPI()}/liveagent/${agentUpdate.workspace}/v1/agent/${
          agentUpdate.agentID
        }`,
        agentUpdate.status
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('agentList');
    },
  });
  return { agentStatus, isError, isLoading, ismutationSuccess: isSuccess };
};

export const useUpdateAgent = (success: () => void, failure: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: updateAgent,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (tagValue: AddTagT) =>
      Axios.patch(
        `${getBaseAPI()}/liveagent/${tagValue.workspace}/v1/session/${
          tagValue.sessionID
        }`,
        tagValue.tagInfo
      ),
    onSuccess: () => {
      success();
      queryClient.invalidateQueries('singleStatus');
      queryClient.invalidateQueries('myStatus');
    },
    onError: () => {
      failure();
    },
  });
  return {
    updateAgent,
    isError,
    isAddTagLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};
export const useTitleTag = (error: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: titleUpdate,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (tagValue: AddTagT) =>
      Axios.patch(
        `${getBaseAPI()}/liveagent/${tagValue.workspace}/v1/session/${
          tagValue.sessionID
        }/title`,
        tagValue.tagInfo
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('singleStatus');
    },
    onError: () => {
      error();
    },
  });
  return {
    titleUpdate,
    isError,
    isTitleLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};

export const fetchUserInteraction = async ({ queryKey }: any) => {
  const [_, workspace, userID, agentID, sessionID] = queryKey;
  const data: UserInteractionT = !mockData
    ? await getJson(
        `${getBaseAPI()}/liveagent/${workspace}/v1/user_interaction?user_id=${userID}&agent_id=${agentID}&session_id=${sessionID}`
      )
    : mockUserData;
  return data;
};

export const useInteraction = (
  workspace: string,
  userID: string,
  agentID: number,
  sessionID: string
) => {
  const {
    data: userInteractionData,
    refetch: userRetry,
    dataUpdatedAt,
    isFetched,
  } = useQuery(
    ['userinteraction', workspace, userID, agentID, sessionID],
    fetchUserInteraction,
    {
      refetchInterval: 30000,
      staleTime: 30000,
      enabled: !!userID,
    }
  );
  return {
    userInteractionData,
    userRetry,
    dataUpdatedAt,
    istagFetched: isFetched,
  };
};
// export function comboBox({ inputValue, setInputValue, categories, value, onChange, setSelected }: any) {
//   categories = [{ name: 'All', id: '' }, ...categories]
//   const displayItem = (item: any | null) => (item ? item.name : '')
//   const searchBox: any = useRef(null)
//   const [inputItems, setInputItems] = useState(categories)
//   const {
//       isOpen,
//       selectedItem,
//       getToggleButtonProps,
//       getMenuProps,
//       getInputProps,
//       getComboboxProps,
//       getItemProps
//   } = useCombobox({
//       items: inputItems,
//       selectedItem: value,
//       itemToString: displayItem,
//       onSelectedItemChange: ({ selectedItem }) => {
//           onChange(selectedItem)
//           setSelected({ selectedCategory: selectedItem.name, selectedApp: '', selectedAppIcon: '' })
//       },
//       inputValue,
//       onInputValueChange: ({ inputValue }: any) => {
//           setInputItems(
//               categories.filter(({ name: item }: { name: string }) =>
//                   item.toLowerCase().startsWith(inputValue.toLowerCase())
//               )
//           )
//           setInputValue(inputValue)
//       }
//   })
//   useEffect(() => {
//       if (isOpen && searchBox.current) {
//           searchBox.current.focus()
//           setInputValue('')
//           setInputItems(categories)
//       }
//   }, [isOpen])
//   return (
//       <DropdownWrapper>
//           <InputContainer>
//               <div {...getComboboxProps()}>
//                   {!isOpen ? (
//                       <Input {...getToggleButtonProps()} value={selectedItem && displayItem(selectedItem)} />
//                   ) : (
//                       <Input
//                           {...getInputProps({
//                               ref: searchBox
//                           })}
//                       />
//                   )}
//                   <InputContent>Select app category</InputContent>
//                   <CancelButton>
//                       <DropdownWithCircle />
//                   </CancelButton>
//               </div>
//               <DropdownUlWrapper>
//                   <DropdownUl {...getMenuProps()}>
//                       {isOpen ? (
//                           inputItems.length !== 0 ? (
//                               inputItems.map((item: any, index: number) => {
//                                   return (
//                                       <DropdownLi key={`${item.name}${index}`} {...getItemProps({ item, index })}>
//                                           {item.name}
//                                       </DropdownLi>
//                                   )
//                               })
//                           ) : (
//                               <DropdownLi>No match found</DropdownLi>
//                           )
//                       ) : null}
//                   </DropdownUl>
//               </DropdownUlWrapper>
//           </InputContainer>
//       </DropdownWrapper>
//   )
// }
const objConv = (
  newArray: {
    [x: string]: string;
  }[]
) => {
  const newObj = newArray.reduce((acc, current) => {
    return { ...acc, ...current };
  });
  return newObj;
};
export const useAttributes = (error: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: attributeUpdate,
    isError,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: (tagValue: AttrT) =>
      Axios.patch(
        `${getBaseAPI()}/liveagent/${tagValue.workspace}/v1/session/${
          tagValue.sessionID
        }/custom_attributes`,
        tagValue.attributes.length > 0
          ? JSON.stringify(objConv(tagValue.attributes))
          : JSON.stringify({}),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('singleStatus');
    },
    onError: () => {
      error();
    },
  });
  return {
    attributeUpdate,
    isError,
    isAttributeLoading: isLoading,
    ismutationSuccess: isSuccess,
  };
};
export type ChatDataT = {
  session_log_id: string;
  chat_id: string;
  location: string | null;
  tags: {
    tag: string;
    // name: string;
    id: string;
  }[];
  session_id: string;
  events: [];
  //agent_name: string;
  //user_name: string;
  agent_email: string;
  user_email: string;
  created_at: string;
  bot_id: string;
  agent_id: string;
  user_id: string;
  conversation_id: string;
  platform: string;
  feedback: string | null;
};

export type ChatHistoryT = {
  data: ChatDataT[];
  totalCount: [
    {
      count: number;
    }
  ];
};

export const MockHistory: ChatDataT[] =[
    {
        "_id": "675701bda798c2bc7e02e45a",
        "event_name": "user_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "f0cdeaef-e165-485a-a72d-6e099e06b254",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:29:38.060Z",
        "input": "e",
        "__v": 0
    },
    {
        "_id": "675701bda798c2bc7e02e45f",
        "event_name": "bot_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "b91f9bd2-0ab5-4787-b132-133779baa564",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:29:38.103Z",
        "output": [],
        "reply_for": "f0cdeaef-e165-485a-a72d-6e099e06b254",
        "__v": 0
    },
    {
        "_id": "675701bda798c2bc7e02e462",
        "event_name": "bot_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "307f96ce-3188-4462-821a-f7153312a234",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:29:38.149Z",
        "output": [
            {
                "type": "Text",
                "text": {
                    "md_type": "md_join",
                    "lhs": {
                        "md_type": "md_normal",
                        "value": "Hi "
                    },
                    "rhs": {
                        "md_type": "md_normal",
                        "value": "Sri"
                    }
                }
            }
        ],
        "reply_for": "f0cdeaef-e165-485a-a72d-6e099e06b254",
        "__v": 0
    },
    {
        "_id": "675701bda798c2bc7e02e465",
        "event_name": "user_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "7460dd9c-066c-4166-b28f-3f5beb25bb91",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:29:45.940Z",
        "input": "agent",
        "__v": 0
    },
    {
        "_id": "675701bda798c2bc7e02e468",
        "event_name": "bot_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "0131d344-b2a7-44ff-a4ec-c874a8317487",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:30:46.071Z",
        "output": [],
        "reply_for": "7460dd9c-066c-4166-b28f-3f5beb25bb91",
        "__v": 0
    },
    {
        "_id": "675701bda798c2bc7e02e46b",
        "event_name": "bot_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "d0d7e76f-e2b9-4794-9275-cc248bc0e240",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:30:46.116Z",
        "output": [],
        "reply_for": "7460dd9c-066c-4166-b28f-3f5beb25bb91",
        "__v": 0
    },
    {
        "_id": "675701bda798c2bc7e02e46e",
        "event_name": "bot_message",
        "session_id": "tryme_f04a371d-5975-4945-bf06-d1f207df5d81",
        "event_id": "07bcc3d8-db67-4c72-b243-391203381aa9",
        "chat_channel": "try_it",
        "bot_id": "testing_bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
        "ws": "vinoddev",
        "time": "2024-12-09T14:30:46.157Z",
        "output": [
            {
                "type": "AgentHandOver",
                "platform": "liveagent",
                "agent_id": "workativ_liveagent",
                "agent_message": {
                    "md_type": "md_join",
                    "lhs": {
                        "md_type": "md_normal",
                        "value": "Sri"
                    },
                    "rhs": {
                        "md_type": "md_normal",
                        "value": " want to connect with Liveagent"
                    }
                },
                "meta": {
                    "teams": "7efe4f42-90e4-4bc9-a306-9ad7133b771d",
                    "bot_id": "bbc2158d-c9e7-4001-9e3d-ec8e67994e00"
                }
            }
        ],
        "reply_for": "7460dd9c-066c-4166-b28f-3f5beb25bb91",
        "__v": 0
    }
];

export const fetchChatHistory = async ({ queryKey }: any) => {
  const [_, workspace, currentPage] = queryKey;
  // const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID
  const data: ChatHistoryT = !mockchat
    ? await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/sessions/30?sort_by_start_time=-1&page_no=${currentPage}&no_of_records=10`
      )
    : MockHistory;
  return data;
};

export const useChatHistory = (workspace: string, currentPage: number) => {
  const {
    data: chatHistoryData,
    //   status: overAllSummaryStatus,
    refetch: chatRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(['chatHistory', workspace, currentPage], fetchChatHistory, {
    staleTime: 30000,
  });

  return {
    chatHistoryData,
    chatRetry,
    dataUpdatedAt,
    isRefetching,
    isOpenFetched: isFetched,
  };
};

export const fetchLogAgentList = async ({ queryKey }: any) => {
  const [_, workspace, agentName] = queryKey;
  // const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID
  const data = !mockchat
    ? agentName === ''
      ? await getJson(`${getBaseAPI()}/la-logs/${workspace}/sessions/agents`)
      : await getJson(`${getBaseAPI()}/la-logs/${workspace}/sessions/agents`)
    : mockAgent;
  return data.data;
};

export const useLogAgentList = (workspace: string, agentName: any) => {
  const {
    data: agentLogList,
    //   status: overAllSummaryStatus,
    refetch: agentRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(['chatHistory', workspace, agentName], fetchLogAgentList, {
    staleTime: 30000,
  });

  return {
    agentLogList,
    agentRetry,
    dataUpdatedAt,
    isRefetching,
    isOpenFetched: isFetched,
  };
};
export const fetchSessionEvent = async ({ queryKey }: any) => {
  const [_, workspace, sessionID] = queryKey;
  // const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID
  const data: eventType[] = !eventMock
    ? await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/session/${sessionID}/events`
      )
    : eventList;
  return data;
};

export const useSessionEvent = (workspace: string, sessionID: string) => {
  const {
    data: singleEventData,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(['singleEvent', workspace, sessionID], fetchSessionEvent, {
    staleTime: 30000,
  });

  return {
    singleEventData,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isOpenFetched: isFetched,
  };
};

export const getValues = (obj) => {
  const key = Object.keys(obj);
  if (key.length === 1) {
    const keys = key[0];
    return obj[keys];
  } else {
    return obj;
  }
};

export const fetchTeamHistory = async ({ queryKey }: any) => {
  const [_, workspace, sessionID, conversationID] = queryKey;
  // const botId = selectedBotID === 'Inbox' ? 'all' : selectedBotID
  const data = !mockchat
    ? await getJson(
        `${getBaseAPI()}/agent-logs/${workspace}/bots/${sessionID}/events?session_id=${conversationID}`
      )
    : MockHistory;
  console.log(data, 'DOING ::: all data');
  return data;
};

export const useTeamHistory = (
  workspace: string,
  sessionID: string,
  conversationID: string
) => {
  const {
    data: teamHistoryData,
    //   status: overAllSummaryStatus,
    refetch: chatRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(
    ['chatHistory', workspace, sessionID, conversationID],
    fetchTeamHistory,
    {
      staleTime: 30000,
      enabled: conversationID !== '',
      refetchInterval:(data) => {
        console.log(data,"testingrefetchInterval")
      if (data && data.length>0) {
        return false; 
      }
      return 5000;
    }
    }
  );

  return {
    teamHistoryData,
    chatRetry,
    dataUpdatedAt,
    isRefetching,
    isOpenFetched: isFetched,
  };
};

export const dataConversion = (data: any[]) => {
  console.log(data,"dataConv")
  const conData = data.map((res) => {

    // const concatedResponse = res.response.reduce(
    //   (acc: any, curr: any) => {
    //     return {
    //       ...acc,
    //       intents: curr.intents,
    //       entities: curr.entities,
    //       context: res.request.context_variables,
    //       faq: curr.faq,
    //       output: acc.output.concat(curr.output),
    //     };
    //   },
    //   { intents: [], entities: [], faq: [], context: null, output: [] }
    // );
    return {
      text: res.input,
      userMessageTime: res.time,
      botMessageTime: res.time,
      req_id: res.req_id,
      botId:res.bot_id,
      output: res.output && res.output
      .length>0 ?
        res.output.map((e: any) => {
          return e.type == 'Text'
            ? {
                response_type: 'text',
                text: e.text,
              }
            : e.type == 'Option'
            ? {
                response_type: 'text',
                title: e.title,
                options: e.options.map((e: any) => ({
                  label: e.label,
                })),
              }
            : e.type == 'AdaptiveCard'
            ? {
                response_type: 'text',
                title: e.blocks.filter(
                  (b: any) => b.text && b.text.style == 'bold'
                )[0].text.text,
                description: e.blocks.filter(
                  (b: any) => b.text && b.text.style == 'normal'
                )[0].text.text,
                fields: e.blocks.filter((b: any) => b.fields)[0]
                  .fields,
                buttons: e.blocks.filter(
                  (b: any) => b.elements
                )[0].elements,
                image: e.blocks.filter((b: any) => b.fields)[0]
                  .accessory,
                adaptive_card: 'adaptive_card',
              }
            : e.type == 'Carousal'
            ? {
                response_type: 'text',
                values: e.accumulate_carousal.map((carousal: any) => ({
                  title: carousal.blocks.filter(
                    (b: any) => b.text && b.text.style == 'bold'
                  )[0].text.text,
                  description: carousal.blocks.filter(
                    (b: any) => b.text && b.text.style == 'normal'
                  )[0].text.text,
                  fields: carousal.blocks.filter((b: any) => b.fields)[0]
                    .fields,
                  buttons: carousal.blocks.filter((b: any) => b.elements)[0]
                    .elements,
                  image: carousal.blocks.filter((b: any) => b.fields)[0]
                    .accessory,
                })),
                adaptive_card: 'carousal',
              }
            : null;
        })
        .filter(Boolean)
        :
        [],
    };
  });
  console.log(data,conData,"conData");
  return conData;
};

export const useConversation = (workspace: string, sessionID: string) => {
  const { singleSessionData, isSessionFetched } = useSingleStatus(
    workspace,
    sessionID
  );
  const [conversationID, setConversationID] = useState<string>('');
  const [botID, setBotID] = useState<string>('');
  const [teamConvData, setTeamConvData] = useState<[]>([]);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (singleSessionData) {
      setBotID(singleSessionData.chat_source.id);
      const parseMeta = singleSessionData.meta;
      const id: string = parseMeta.user_conversation;
      const splitID = id?.split('/');
      const converID = splitID[splitID.length - 1];
      setConversationID(converID);
    }
  }, [singleSessionData]);

  const { teamHistoryData } = useTeamHistory(workspace, botID, conversationID);
  console.log(teamHistoryData,"teamHistoryData")
  useEffect(()=>{
    if(teamHistoryData && teamHistoryData.length === 0){
      queryClient.invalidateQueries("chatHistory")
    }
  },[teamHistoryData])

  const dataConvert =
    teamHistoryData && teamHistoryData.length > 0
      ? dataConversion(teamHistoryData)
      : [];

  const { singleEventData } = useSessionEvent(workspace, sessionID);
  const { messageHistory } = useContext(MessageArray);

  const convertSinlgeEvent = singleEventData
    ? singleEventData.map((ev) => getValues(ev))
    : [];
  const withEventList = [...convertSinlgeEvent];
  const onlySessionMH = messageHistory.filter(
    (mh) => mh.data.session_id === sessionID
  );

  const mhID = onlySessionMH.length > 0 ? onlySessionMH.map((d) => d.id) : [];
  const removeEvents =
    mhID.length > 0
      ? withEventList.filter((ev) => !mhID.includes(ev.event_id))
      : withEventList;

  const allMessageEvents = [...dataConvert, ...removeEvents, ...onlySessionMH];

  return {
    singleSessionData,
    allMessageEvents,
    isSessionFetched,
    onlySessionMH,
  };
};
const getDateNumber = (date: string) => {
  switch (date) {
    case 'today':
      return 'today';
    case 'yesterday':
      return 'yesterday';
    case 'last30days':
      return 30;
    case 'last14days':
      return 14;
    case 'last7days':
      return 7;
    default:
      return 'today2';
  }
};
export const fetchChatStatus = async ({ queryKey }: any) => {
  const [_, workspace, dateOptions, customDates] = queryKey;
  const custom =
    dateOptions &&
    (dateOptions === 'customrange' ? customDates?.from_date !== null : true);
  const data: ChatStausT = custom
    ? await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/chat_status?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }`
      )
    : await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/chat_status?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber('last30days')
        }`
      );
  return data;
};

export const getChatStatus = (
  workspace: string,
  dateOptions: string,
  customDates: Customdate
) => {
  const {
    data: chatStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(
    ['chatStatus', workspace, dateOptions, customDates],
    fetchChatStatus,
    {
      staleTime: 30000,
    }
  );

  return {
    chatStatus,
    // sessionRetry,
    // dataUpdatedAt,
    // isRefetching,
    // isOpenFetched: isFetched,
  };
};

export const fetchChatAssignment = async ({ queryKey }: any) => {
  const [_, workspace, dateOptions, customDates] = queryKey;
  const custom =
    dateOptions &&
    (dateOptions === 'customrange' ? customDates?.from_date !== null : true);
  const data: chatAssignmentT = custom
    ? await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/chat_assignment_status?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }`
      )
    : await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/chat_assignment_status?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber('last30days')
        }`
      );
  return data;
};

export const getChatAssignment = (
  workspace: string,
  dateOptions: string,
  customDates: Customdate
) => {
  const {
    data: chatAssignment,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(
    ['chatAssignment', workspace, dateOptions, customDates],
    fetchChatAssignment,
    {
      staleTime: 30000,
    }
  );

  return {
    chatAssignment,
    // sessionRetry,
    // dataUpdatedAt,
    // isRefetching,
    // isOpenFetched: isFetched,
  };
};

export type AgentResponseT = {
  avgFirstResponse: string;
  agent: string;
  avgResolution: string;
  assignedCount: number;
  satisfiedPercentage: number;
  notSatisfiedPercentage: number;
  resolvedCount: number;
};

export const fetchAgentResponse = async ({ queryKey }: any) => {
  const [_, workspace, dateOptions, customDates] = queryKey;
  const custom =
    dateOptions &&
    (dateOptions === 'customrange' ? customDates?.from_date !== null : true);
  const data: AgentResponseT[] = custom
    ? await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/agent_response?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }`
      )
    : await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/agent_response?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber('last30days')
        }`
      );
  return data;
};

export const getAgentResponse = (
  workspace: string,
  dateOptions: string,
  customDates: Customdate
) => {
  const {
    data: agentResponse,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(
    ['agentResponse', workspace, dateOptions, customDates],
    fetchAgentResponse,
    {
      staleTime: 30000,
    }
  );

  return {
    agentResponse,
    // sessionRetry,
    // dataUpdatedAt,
    // isRefetching,
    // isOpenFetched: isFetched,
  };
};
export type StatusbyLocT = ChatStausT & {
  Location: string;
};

export type LiveChatAgentsT = {
  Online: number;
  ChattingWithUser: number;
  Total: number;
  agent: string;
};

export type LiveChatStatusT = {
  Open: number;
  WaitingInQueue: number;
  AwaitingAgentResponse: number;
};

export type LiveAgentResponseT = {
  openChats: number;
  agent: string;
  avgFirstResponse: string;
  avgResolution: string;
};

export const fetchStatusByloc = async ({ queryKey }: any) => {
  const [_, workspace, dateOptions, customDates] = queryKey;
  const custom =
    dateOptions &&
    (dateOptions === 'customrange' ? customDates?.from_date !== null : true);
  const data: StatusbyLocT[] = custom
    ? await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/chat_status_by_location?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber(dateOptions)
        }`
      )
    : await getJson(
        `${getBaseAPI()}/la-logs/${workspace}/analytics/chat_status_by_location?${
          dateOptions === 'customrange' && customDates?.from_date !== null
            ? 'day_span=custom&to_date=' +
              customDates.to_date +
              '&from_date=' +
              customDates.from_date
            : 'day_span=' + getDateNumber('last30days')
        }`
      );
  return data;
};

export const useStatusByloc = (
  workspace: string,
  dateOptions: string,
  customDates: Customdate
) => {
  const {
    data: statusByloc,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(
    ['statusByloc', workspace, dateOptions, customDates],
    fetchStatusByloc,
    {
      staleTime: 30000,
    }
  );

  return {
    statusByloc,
    // sessionRetry,
    // dataUpdatedAt,
    // isRefetching,
    // isOpenFetched: isFetched,
  };
};

export const fetchLiveAgentRes = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: LiveAgentResponseT[] = await getJson(
    `${getBaseAPI()}/la-logs/${workspace}/analytics/live_agent_response`
  );
  return data;
};

export const getLiveAgentRes = (workspace: string) => {
  const { data: liveAgentResponse } = useQuery(
    ['liveAgentRes', workspace],
    fetchLiveAgentRes,
    {
      staleTime: 30000,
    }
  );
  return {
    liveAgentResponse,
  };
};

export const fetchLiveChatAgents = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: LiveChatAgentsT =
    // MockLiveAgent
    await getJson(
      `${getBaseAPI()}/la-logs/${workspace}/analytics/live_chat_agents`
    );
  return data;
};

export const getliveChatAgents = (workspace: string) => {
  const { data: liveChatAgents } = useQuery(
    ['liveChatAgents', workspace],
    fetchLiveChatAgents,
    {
      staleTime: 30000,
    }
  );

  return {
    liveChatAgents,
  };
};

export const fetchLiveChatStatus = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: LiveChatStatusT = await getJson(
    `${getBaseAPI()}/la-logs/${workspace}/analytics/live_chat_status`
  );
  return data;
};

export const getLiveChatStatus = (workspace: string) => {
  const { data: liveChatStatus } = useQuery(
    ['liveChatStatus', workspace],
    fetchLiveChatStatus,
    {
      staleTime: 30000,
    }
  );

  return {
    liveChatStatus,
  };
};

export const getLiveagentBots = (
  workspaceName: string
): Promise<AssistantT[]> => {
  return getJson(`${getBaseAPI()}/liveagent/${workspaceName}/v1/source`).then(
    (data) =>
      data.map((e: any) => ({
        ...e,
        updated: e.updated + 'Z',
        created: e.created + 'Z',
        deployed: e.deployed === null ? null : e.deployed + 'Z',
      }))
  );
};
export const fetchLiveAgentBot = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: LiveChatStatusT = await getJson(
    `${getBaseAPI()}/liveagent/${workspace}/v1/source`
  );
  return data;
};

export const useBots = (workspace: string) => {
  const {
    data,
    refetch: sessionRetry,
    status,
    dataUpdatedAt,
    isRefetching,
    isFetched,
  } = useQuery(['liveAgentBot', workspace], fetchLiveAgentBot, {
    staleTime: Infinity,
    // select: (bots: AssistantT[]) => {
    //   return bots.map((bot) => ({ id: bot.id, name: bot.name } as BotsLA));
    // },
  });

  return {
    bots: data,
    sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isBotsFetched: isFetched,
  };
};
type TeamMember = {
  id: string;
  auth_user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  status: string;
  deleted: number;
};
export type TeamData = {
  id: string;
  name: string;
  team_members: TeamMember[];
};

type AgentDetail = {
  agentID: string | number;
  agentName: string;
};

type TeamResult = {
  team: { id: string; name: string };
  agentDetails: AgentDetail[];
};
export const fetchLiveAgents = async ({ queryKey }: any) => {
  const [_, workspace, sessionID] = queryKey;
  const data: TeamData[] = !mockData
    ? await getJson(
        `${getBaseAPI()}/liveagent/${workspace}/v1/session/${sessionID}/teams`
      )
    : mockAgent;
  return data;
};

export const useLiveAgents = (workspace: string, sessionID: string) => {
  const {
    data: LiveAgentsList,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    dataUpdatedAt,
    isRefetching,
    isFetching,
  } = useQuery(['liveAgentsList', workspace, sessionID], fetchLiveAgents, {
    // refetchInterval: 30000,
    staleTime: Infinity,
    enabled: sessionID !== '',
  });

  return {
    LiveAgentsList,
    // sessionRetry,
    // dataUpdatedAt,
    // isRefetching,
    // isAgentFetching: isFetching,
  };
};
export const fetchTagList = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: string[] = await getJson(
    `${getBaseAPI()}/liveagent/${workspace}/v1/search_tags`
  );
  // : MockHistory.data;
  return data;
};

export const useTagList = (workspace: string) => {
  const { data: tagList } = useQuery(['tagList', workspace], fetchTagList, {
    // refetchInterval: 30000,
    staleTime: Infinity,
  });

  return {
    tagList,
  };
};

export const fetchAdminData = async ({ queryKey }: any) => {
  const [_, workspace] = queryKey;
  const data: User[] = MOCK_USER_API
    ? [
        {
          id: 59,
          first_name: 'Sangeetha',
          last_name: 'Udayakumar',
          email: 'sangeetha.n@workativ.com',
          verified: true,
          has_password: true,
          Roles: [
            {
              id: 1,
              name: 'super_admin',
              display_name: 'Admin',
              workspace: null,
              meta: {},
              createdAt: '2023-03-15T18:17:28.000Z',
              updatedAt: '2023-03-15T18:17:28.000Z',
            },
          ],
          AccessibleWorkspaces: [
            {
              name: 'dhana',
              ownerId: 339,
              WorkspaceAccess: {
                valid: true,
              },
            },
          ],
          CountryCode: {
            name: 'India',
            ISOcode: 'IN',
          },
          MobileNumberVsUser: null,
        },
        {
          id: 235,
          first_name: 'Arunadevi',
          last_name: 'Rathinakumar',
          email: 'arunadevi.r@workativ.com',
          verified: true,
          has_password: true,
          Roles: [
            {
              id: 1,
              name: 'super_admin',
              display_name: 'Admin',
              workspace: null,
              meta: {},
              createdAt: '2023-03-15T18:17:28.000Z',
              updatedAt: '2023-03-15T18:17:28.000Z',
            },
          ],
          AccessibleWorkspaces: [
            {
              name: 'dhana',
              ownerId: 339,
              WorkspaceAccess: {
                valid: true,
              },
            },
          ],
          CountryCode: null,
          MobileNumberVsUser: {
            id: 135,
            mobileNo: '7550242010',
            verified: false,
            valid: true,
            createdAt: '2023-03-16T08:34:55.000Z',
            updatedAt: '2023-03-16T08:34:55.000Z',
            code: '1',
            userId: 235,
          },
        },
        {
          id: 339,
          first_name: 'Dhanasekaran',
          last_name: 'T',
          email: 'dhanasekaran.t@workativ.com',
          verified: true,
          has_password: true,
          Roles: [
            {
              id: 1,
              name: 'super_admin',
              display_name: 'Admin',
              workspace: null,
              meta: {},
              createdAt: '2023-03-15T18:17:28.000Z',
              updatedAt: '2023-03-15T18:17:28.000Z',
            },
          ],
          AccessibleWorkspaces: [
            {
              name: 'dhana',
              ownerId: 339,
              WorkspaceAccess: {
                valid: true,
              },
            },
          ],
          CountryCode: null,
          MobileNumberVsUser: {
            id: 191,
            mobileNo: '9876543210',
            verified: false,
            valid: true,
            createdAt: '2023-09-13T05:24:14.000Z',
            updatedAt: '2023-09-13T05:24:14.000Z',
            code: '1',
            userId: 339,
          },
        },
      ]
    : await getJson(loginApiURL(`/access/v2/${workspace}/users`));

  return data;
};

export const useAdminData = (workspace:string) => {
  const {
    data:adminData,
    //   status: overAllSummaryStatus,
    refetch: sessionRetry,
    status,
    dataUpdatedAt,
    isRefetching,
  } = useQuery(['admins',workspace], fetchAdminData, {
    staleTime: Infinity,
  });
  return { adminData, sessionRetry, dataUpdatedAt, isRefetching };
};

export const mockChatHistory ={
    "data": [
        {
            "chat_id": "17",
            "location": "",
            "tags": [],
            "agent_email": "vinod.m@workativ.com",
            "user_email": "vinod.m@workativ.com",
            "created_at": "2024-12-16T12:41:15.362Z",
            "bot_id": "bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
            "agent_id": "36",
            "event_ids": [
                "67601febba67f9709569ed7c",
                "6760200fba67f9709569ed8c"
            ],
            "session_id": "ed7aa1b3-ad3c-471b-8f55-db609df09fe9",
            "conversation_id": "tryme_cf39ec53-aaf6-4739-ba7b-e50eb94af70e",
            "platform": "tryme",
            "feedback": null
        },
        {
            "chat_id": "16",
            "location": "",
            "tags": [
                {
                    "id": 55,
                    "tag": "Test",
                    "_id": "675c37f8ba67f9709569ed62"
                }
            ],
            "agent_email": "vinod.m@workativ.com",
            "user_email": "vinod.m@workativ.com",
            "created_at": "2024-12-12T15:19:10.183Z",
            "bot_id": "bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
            "agent_id": "36",
            "event_ids": [
                "675afeee6d207532618583e9",
                "67600871ba67f9709569ed74"
            ],
            "session_id": "95db2fb7-0518-4cc4-be12-e23dddbc3217",
            "conversation_id": "tryme_7c12954f-303f-4f71-b6bb-35a05fd236f5",
            "platform": "tryme",
            "feedback": null
        },
        {
            "chat_id": "15",
            "location": "India",
            "tags": [],
            "agent_email": "vinod.m@workativ.com",
            "user_email": "vinod.m@workativ.com",
            "created_at": "2024-12-10T10:30:27.785Z",
            "bot_id": "bd1fb0b7-9f1d-4a44-bac4-0ceb9b2227d1",
            "agent_id": "36",
            "event_ids": [
                "6758184359463ef732fe1859"
            ],
            "session_id": "0d97f9a0-aa1f-4f43-8aff-4d206840ebb4",
            "conversation_id": "tryme_7867a86f-efbf-4bc2-8826-10d76129cca7",
            "platform": "tryme",
            "feedback": null
        },
        {
            "chat_id": "14",
            "location": "UK",
            "tags": [],
            "agent_email": "vinod.m@workativ.com",
            "user_email": "vinod.m@workativ.com",
            "created_at": "2024-12-10T09:41:45.715Z",
            "bot_id": "bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
            "agent_id": "36",
            "event_ids": [
                "67580cd959463ef732fe1847"
            ],
            "session_id": "d2e13f98-6d59-4c5d-90b8-c1eff6da7925",
            "conversation_id": "tryme_8c991797-78fa-4123-a2a1-0a0e5e385e5c",
            "platform": "tryme",
            "feedback": null
        },
        {
            "chat_id": "13",
            "location": "",
            "tags": [],
            "agent_email": "vinod.m@workativ.com",
            "user_email": "vinod.m@workativ.com",
            "created_at": "2024-12-10T09:12:27.836Z",
            "bot_id": "bbc2158d-c9e7-4001-9e3d-ec8e67994e00",
            "agent_id": "36",
            "event_ids": [
                "675805fb59463ef732fe1835"
            ],
            "session_id": "63a4cd1f-1e25-4b5a-8312-63ad854c3483",
            "conversation_id": "tryme_2d6d1bdc-ae7c-462b-8bbe-e795c495f8c1",
            "platform": "tryme",
            "feedback": null
        }
    ],
    "totalCount": 5
}