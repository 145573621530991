import {
  ActionT,
  BotsLA,
  PageStateLive,
  Sessions,
  VIEW,
  currentTab,
  tab,
  AgentStatusT,
  StatusM,
  notificationEvent,
} from './LiveagentMockFile';
import {
  AddIcon,
  BotDropdown,
  CheckIcon,
  DeleteIcon,
} from '@/common/Icons/Icons';
import { ButtonWrapper, Paragraph } from '@/common/styled/Dialogue.Dumb';
import React, { useEffect, useState } from 'react';
import { TabHeader } from '@/common/styled/Settings.Dumb';
import {
  DateFilterT,
} from 'libs/feature/assistant/src/Dialogue/BotDetails';
import {
  MessageArray,
  tabUpdate,
  useAgentList,
  useAgentStatus,
  useAllSession,
  useBots,
  useClosedSessions,
  useConnectWebSocket,
  useDeleteSession,
  useMentionStatus,
  useMultiDelete,
  useMySessions,
  useOpenStatus,
  useQueusStatus,
  useTrashedStatus,
  useUpdateStatus,
  useUserData,
  WebSocketContext,
} from './LivaAgentApi';

import {
  Button,
} from '@/common/styled/Dialog.BotDetails.Dumb';
import Downshift from 'downshift';
import { LiveAgentInbox } from './inbox';
import { Modal } from '@/common/components/Modal';
import { Loader } from '@/common/components/Loader';
import { NotFoundPage } from '@/404/NotFound';
import { useQueryClient } from 'react-query';
import { WarningAlertIcon } from '@/common/Icons/Workflow.Icons';
import { NotificationEvent, SessionEvents } from './LiveAgentTypes';
import LiveAgentNotification from './LiveAgentNotification';
import { HeaderForLiveAgent } from '.';
import { TabLeft, TabRight } from '@/Flows/analytics/logs';
import styled from 'styled-components';
import { useAllTeams } from '@/ManageUsers/v2/manageUsersApi';
export const statusList = [
  'all',
  'queued-sessions',
  'my-session',
  'open-sessions',
  'closed-sessions',
  'trash',
  'conversations',
  'mention-sessions',
];


export const TabRow = styled.div`
    width: 100%;
    float: left;
    position:relative;
    display: flex;
    justify-content: space-between;

      ${TabLeft}{
        width: fit-content;
        :after{
          content: "";
          position: absolute;
          border-bottom: 1px solid #e0e0e0;
          width: 24.4%;
          height: 1px;
          top: 33px;
          left: 4.5px;
          z-index: 0;

          // @media (min-width: 1920px) {
          //     width: 39%;
          // }
          // @media (min-width: 2160px) {
          //     width: 34.5%;
          // }
          // @media (min-width: 2560px) {
          //     width: 28.5%;
          // }
          // @media (min-width: 3480px) {
          //     width: 20.5%;
          // }
          // @media (max-width: 1440px) {
          //     width: 25%;
          // }
          // @media (max-width: 1280px) {
          //     width: 60%;
          // }
      }
  }

  ${TabRight}{
    width: 25%;
  }
`

export const TabContainer = styled.div`
    width: 100%;
    float: left;
    display: flex;
    height: 70px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 10px;
    padding: 16px 20px;
    margin-bottom: 20px;
    align-items: baseline;    

    .liveAgent-subMenu-row{
      justify-content: flex-start;
    }
`

const inboxBot: BotsLA = { name: 'Inbox', id: 'Inbox' };
export function LiveAgentMenu(props: any) {
  console.log(props, "LiveagentProps")
  const workspace = props.match.params.workspacename;
  const tabLoc = props.match.params.status;
  const tabID = props.match.params.ID;
  const tabBot = props.match.params.agentId as string;
  const [pageState, setPageState] = useState<PageStateLive>(VIEW);
  const [tab, setTab] = useState<tab>('Bot');
  const queryClient = useQueryClient();
  const [currentTab, setCurrentTab] = useState<currentTab>(tabUpdate(tabLoc));
  const [showState, setState] = useState({ customDatePicker: 'hide' });
  useEffect(()=>{
    setCurrentTab(tabUpdate(tabLoc))
  },[tabLoc])
  //dateDropdown
  // type Customdate = {
  //   from_date: null | string;
  //   to_date: null | string;
  // };
  // const initialDates = {
  //   from_date: null,
  //   to_date: null,
  // };
  // const [customDates, setCustomDates] = useState<Customdate>(initialDates);
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [totalCount, setTotalCount] = useState<number>(0);
  // const [dateOptions, setOptions] = useState('last30days');
  
  // useEffect(() => {
  //   if (dateOptions !== 'customrange') {
  //     setCustomDates(initialDates);
  //     setCurrentPage(1);
  //   }
  // }, [dateOptions]);
 
  // useEffect(() => {
  //   if (dateOptions === 'customrange' && customDates.from_date !== null) {
  //     setCurrentPage(1);
  //   }
  // }, [customDates]);

  const checkChatID = (chatID: number, sessionValue: Sessions[]) => {
    const sessionIDs = sessionValue?.map((ses) => ses.chat_id);
    return sessionIDs?.includes(chatID);
  };
  //websocket initiated here.. used context to store the info
  const {
    readyState,
    message,
    handleClickSendMessage,
    handleCommentSetting,
    handleAttachment,
    transferAgent,
  } = useConnectWebSocket(workspace,statusQueryKey);
  const [notificationArr,setNotificationArr] = useState<any[]>([])
  console.log(notificationArr,"notificationArr")
  // setTimeout(()=>notificationArr.push(notificationEvent), 10000)
  const deleteNotification = (event:NotificationEvent)=>{
    // notificationArr.splice(index,1)
    const removeEvent= notificationArr.filter(ev=>ev.id !== event.id)
    setNotificationArr(removeEvent)
  }
  const [messageHistory, setMessageHistory] = useState<SessionEvents[]>([]);
  //setting and updating msg history
  useEffect(() => {
    const key = Object.keys(message);
    if (key.length === 1) {
      const keys = key[0];
      const existingIndex = messageHistory.findIndex(
        (e) => e.id === message[keys].id
      );

      if (existingIndex >= 0) {
        setMessageHistory((messageHistory) =>
          messageHistory.map((m) =>
            m.id === message[keys].id ? message[keys] : m
          )
        );
      } else {
        setMessageHistory([...messageHistory, message[keys]]);
      }
    } else {
      const existingIndex = messageHistory.findIndex(
        (e) => e.id === message.id
      );

      if (existingIndex >= 0) {
        setMessageHistory((messageHistory) =>
          messageHistory.map((m) => (m.id === message.id ? message : m))
        );
        // messageHistory[existingIndex] = message;
      } else {
        setMessageHistory([...messageHistory, message]);
      }
    }
    if(message.event_type === "queue_notification"){
      setNotificationArr([...notificationArr, message])
      queryClient.invalidateQueries('allSession')
      queryClient.invalidateQueries('myStatus')
      queryClient.invalidateQueries('openStatus')
    }else if (message.event_type === "connect") {
      const row = notificationArr.filter(
        (not) => not.data.session_id !== message.data.session_id,
      );
      setNotificationArr(row);
    }
  }, [message]);
  console.log(message,messageHistory,notificationArr,"messageHistory")
  //getting agentList for 
  const { bData, isBotsFetched, selectedBot, setSelectedBot } = useBotsData(
    workspace,
    tabBot
  );
  const {
    agentList,
    isRefetching,
  } = useAgentList(workspace);
  const { AllTeamsData, TeamFetching } = useAllTeams(workspace);
  const { user } = useUserData();

  const myStatus =
    agentList &&
    user &&
    agentList.filter((agent) => agent.auth_user_id == user.id)[0]?.agent_status;
  const [error, setError] = useState<boolean>(false);
  const {
    AllData,
    isAllSessionsFetched,
    isSessionError,
  } = useAllSession(props.match.params.workspacename, selectedBot.id, 'Newest',0,()=>console.log("working"),user?.id);


  // console.log('DOING ::: all data', totalCount);
  const [botdropdown, setBotdropdown] = useState<boolean>(false);

  // const { queueSessionData } = useQueusStatus(
  //   props.match.params.workspacename,
  //   'queue',
  //   selectedBot.id,
  //   'Newest',0,()=>console.log("working")
  // );
  // const { openSessionData } = useOpenStatus(
  //   props.match.params.workspacename,
  //   'Progress',
  //   selectedBot.id,
  //   'Newest'
  // );
  // const { closedSessionData } = useClosedSessions(
  //   props.match.params.workspacename,
  //   'closed',
  //   selectedBot.id,
  //   'Newest'
  // );
  // const { mySessionData } = useMySessions(
  //   props.match.params.workspacename,
  //   user?.id,
  //   selectedBot.id,
  //   'Newest'
  // );
  // const { mentionSessionData } = useMentionStatus(
  //   props.match.params.workspacename,
  //   'Progress',
  //   selectedBot.id,
  //   'Newest',
  //   user?.id
  // );
  // const { TrashedSessionData } = useTrashedStatus(
  //   props.match.params.workspacename,
  //   'Trash',
  //   selectedBot.id,
  //   'Newest',0,()=>console.log("Working")
  // );

  function statusQueryKey() {
    switch (currentTab) {
      case 'All':
        return 'allSession';
      case 'Queue':
        return 'queueStatus';
      case 'My':
        return 'myStatus';
      case 'Open':
        return 'openStatus';
      case 'Closed':
        return 'closedStatus';
      case 'Trash':
        return 'trashStatus';
      case 'Mention':
        return 'mentionStatus';
    }
  }
  const { statusMutate, isStatusMutateLoading } =
    useUpdateStatus(
      statusQueryKey,
      () => {
        setError(true);
      },
      () => {
        setPageState(VIEW);
        setRestorePopup(false);
        props.history.push(
          `/workspace/${workspace}/live-agent/${tabBot}/${tabLoc}/conversations`
        );
      }
    );

  const { multiDeleteMutate } = useMultiDelete(
    statusQueryKey,
    () => {
      setError(true);
    },
    () => {
      setPageState(VIEW);
      setClosePopup(false);
      setDeletePopup(false);
      props.history.push(
        `/workspace/${workspace}/live-agent/${tabBot}/${tabLoc}/conversations`
      );
    }
  );

  const { DeleteSession, isDeleteSessionLoading } = useDeleteSession(
    () => {
      setError(true);
    },
    () => {
      setPageState(VIEW);
      setPermDeletePopup(false);
      props.history.push(
        `/workspace/${workspace}/live-agent/${tabBot}/${tabLoc}/conversations`
      );
    }
  );
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [closePopup, setClosePopup] = useState<boolean>(false);
  const [restorePopup, setRestorePopup] = useState<boolean>(false);
  const [permDeletePopup, setPermDeletePopup] = useState<boolean>(false);
  const [closeConvo, setCloseConvo] = useState<boolean>(false);
  const linkSelect = (
    selectedBotID: string,
    sessionID: number,
    tab: string
  ) => {
    props.history.push(
      `/workspace/${workspace}/live-agent/${selectedBotID}/${tab}/${sessionID}`
    );
  };

  const CloseConvo = async () => {
    setCloseConvo(true);
    const { data } = pageState;
    const req: StatusM = {
      status: {
        session_ids: data,
        status:'Closed'
      },
      workspace:workspace,
      session_status: 'Closed',
    };
    multiDeleteMutate(req);
  };

  const trashConvo = async () => {
    const { data } = pageState;
    const req: StatusM = {
      status: {
        session_ids: data,
        status:'Trash'
      },
      workspace:workspace,
      session_status: 'Trash',
    };
    multiDeleteMutate(req);
  };

  const selectMultiConvo = (convo: string) => {
    if (pageState.action !== ActionT.MULTITRASH) {
      setPageState({
        action: ActionT.MULTITRASH,
        data: [convo],
      });
    } else if (pageState.data.includes(convo)) {
      const removedArr = pageState.data.filter((da) => da !== convo);
      if (removedArr.length > 0) {
        setPageState({
          action: ActionT.MULTITRASH,
          data: removedArr,
        });
      } else {
        setPageState(VIEW);
      }
    } else {
      const newArr = [...pageState.data, convo];
      setPageState({
        action: ActionT.MULTITRASH,
        data: newArr,
      });
    }
  };
  const selectConvo = (convo: string) => {
    if (pageState.action !== ActionT.MOVETRASH) {
      setPageState({
        action: ActionT.MOVETRASH,
        data: [convo],
      });
    } else if (pageState.data.includes(convo)) {
      const removedArr = pageState.data.filter((da) => da !== convo);
      if (removedArr.length > 0) {
        setPageState({
          action: ActionT.MOVETRASH,
          data: removedArr,
        });
      } else {
        setPageState(VIEW);
      }
    } else {
      const newArr = [...pageState.data, convo];
      setPageState({
        action: ActionT.MOVETRASH,
        data: newArr,
      });
    }
  };
  console.log(pageState, 'pageStatecheck');
  const convoRestore = (convo: string) => {
    if (pageState.action !== ActionT.DELETE) {
      setPageState({
        action: ActionT.DELETE,
        data: convo,
      });
    } else if (pageState.data === convo) {
      setPageState(VIEW);
    } else {
      setPageState({
        action: ActionT.DELETE,
        data: convo,
      });
    }
  };
  const restoreConvo = async () => {
    const { data } = pageState;
    const req = {
      status: {
        session_ids:[data],
        status: 'Closed',
      },
      workspace:workspace,
      sessionID: data,
    };
    statusMutate(req);
  };
  const convoDelete = (convo: string) => {
    if (pageState.action !== ActionT.DELETE) {
      setPageState({
        action: ActionT.DELETE,
        data: convo,
      });
    } else if (pageState.data === convo) {
      setPageState(VIEW);
    } else {
      setPageState({
        action: ActionT.DELETE,
        data: convo,
      });
    }
  };
  const permDeleteConvo = async () => {
    const { data } = pageState;
    const req = {
      status: {
        session_status: 'Restore',
      },
      workspace:workspace,
      sessionID: data,
    };
    DeleteSession(req);
  };

  const { agentStatus } = useAgentStatus();
  const agentID =
    user &&
    agentList &&
    agentList.filter((ag) => ag.auth_user_id === user.id)[0]?.id;

  const updateAgentStatus = (agentID:string) => {
    const update: AgentStatusT = {
      status:{
        agent_status: myStatus === 'Online' ? 'Offline' : 'Online'
      }, 
      workspace: workspace,
      agentID: agentID,
    };
    agentStatus(update);
  };

  if (isSessionError) {
    return <NotFoundPage />;
  } else if (!isBotsFetched || !isAllSessionsFetched || user === undefined) {
    return <Loader.PageLoader show={true} />;
  } else {
    const bots = bData as BotsLA[];
    const teamData = AllTeamsData ? AllTeamsData.map((bot) => ({ id: bot.id, name: bot.name } as BotsLA)) :[]
    // const botList = [inboxBot, ...bots];
    const botList = [inboxBot, ...teamData];


    const changeURL = () => {
      console.log('callled');
      props.history.push(
        `/workspace/${props.match.params.workspacename}/live-agent/${tabBot}/${tabLoc}/conversations`
      );
    };

    const botSelect = (data: BotsLA) => {
      setSelectedBot(data);
      props.history.push(
        `/workspace/${props.match.params.workspacename}/live-agent/${data.id}/all/conversations`
      );
      queryClient.invalidateQueries('allSession');
      setCurrentTab('All');
    };

    return (
      <>
      <HeaderForLiveAgent {...props} />
        {/* <div className="liveAgent_title_section">
          <h2 className="liveAgent_title">Live Chat Inbox <span>Beta</span></h2>
        </div> */}
        <div>
          {/* {tab === 'Bot' ? ( */}
            <TabContainer className="liveAgent-menu inbox">
              <TabRow className="liveAgent-menu-row">
                <TabLeft>
                  <TabHeader
                    className={tab === 'Bot' ? 'active' : ''}
                    onClick={() => setTab('Bot')}
                  >
                    {selectedBot.name}
                    <span
                      onClick={() => {
                        setBotdropdown(true);
                      }}
                      className={tab === 'Bot' ? 'activesvg' : ''}
                    >
                      <BotDropdown />
                    </span>
                    {botdropdown ? (
                      <Downshift
                        isOpen={botdropdown}
                        onOuterClick={() => setBotdropdown(false)}
                      >
                        {() => (
                          <div className="flow_sort_by dialog_dp">
                            <ul className="flow_sort_dropdown">
                              {botList.map((data) => (
                                <li
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    botSelect(data);
                                    setBotdropdown(false);
                                  }}
                                  className={
                                    data.name === selectedBot.name
                                      ? 'liveAt_submenu_active'
                                      : ''
                                  }
                                >
                                  {data.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Downshift>
                    ) : null}
                  </TabHeader>
                  <TabHeader
                    // active={tab === 'DashBoard'}
                    onClick={() => {
                      setTab('DashBoard');
                      props.history.push(
                        `/workspace/${workspace}/live-agent/dashboard`
                      );
                    }}
                  >
                    Dashboard
                  </TabHeader>
                  <TabHeader
                    // active={tab === 'Chat History'}
                    onClick={() => {
                      setTab('Chat History');
                      props.history.push(
                        `/workspace/${workspace}/live-agent/chat-history`
                      );
                    }}
                  >
                    Chat History
                  </TabHeader>
                  {/* <TabHeader
                    active={tab === 'Settings'}
                    onClick={() => {
                      setTab('Settings');
                    }}
                  >
                    Settings
                  </TabHeader> */}
                </TabLeft>
                <TabRight>
                {tab === 'Bot' && (
                  <div className="btn_width_wrapper">
                    {// pageState.action === ActionT.MOVETRASH ||
                    pageState.action === ActionT.SELECT &&
                      pageState.data[0] !== '' &&
                      currentTab !== 'Closed' && (
                        <Button
                          primary
                          className="btn btn-primary"
                          style={{ margin: 0, padding: '6px 12px 7px 12px' }}
                          onClick={() => setClosePopup(true)}
                        >
                          <span className="header_button" style={{width: '14px', height:'14px', top: '2px'}}>
                            <CheckIcon />
                          </span>
                          <label style={{ top: '1.3px' }}>
                            Close Conversation
                          </label>
                        </Button>
                      )}
                    {(pageState.action === ActionT.MOVETRASH ||
                      pageState.action === ActionT.MULTITRASH ||
                      pageState.action === ActionT.CLOSEDTRASH) &&
                      pageState.data[0] !== '' && (
                        <Button
                          primary
                          className="btn btn-primary"
                          style={{ margin: 0 }}
                          onClick={() => setDeletePopup(true)}
                        >
                          <span className="header_button" style={{width: '14px', height:'14px'}}>
                            <DeleteIcon />
                          </span>
                          <label>Move to trash </label>
                        </Button>
                      )}
                    {/* <Button
                  primary
                  className="btn btn-primary"
                  style={{ margin: 0 }}
                >
                  <span className="header_button">
                    <AddIcon />
                  </span>
                  <label>Actions</label>
                </Button> */}
                    {pageState.action === ActionT.DELETE &&
                      pageState.data !== '' && (
                        <Button
                          primary
                          className="btn btn-primary"
                          style={{ margin: 0 }}
                          onClick={() => setPermDeletePopup(true)}
                        >
                          <span className="header_button" style={{width: '14px', height:'14px'}}>
                            <DeleteIcon />
                          </span>
                          <label>Delete forever</label>
                        </Button>
                      )}
                    {pageState.action === ActionT.DELETE &&
                      pageState.data !== '' && (
                        <Button
                          primary
                          className="btn btn-primary"
                          style={{ margin: 0 }}
                          onClick={() => setRestorePopup(true)}
                        >
                          <span className="header_button" style={{width: '14px', height:'14px'}}>
                            <AddIcon />
                          </span>
                          <label>Restore</label>
                        </Button>
                      )}
                  </div>
                )}
                </TabRight>
              </TabRow>
            </TabContainer>
          {tab === 'Bot' && (
            <TabContainer className="liveAgent-subMenu inbox">
              <TabRow style={{width: '100%'}} className="liveAgent-subMenu-row">
                <TabHeader
                  className={currentTab === 'All' ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab('All');
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/all/conversations`
                    );
                  }}
                >
                  All
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Queue' ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab('Queue');
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/queued-sessions/conversations`
                    );
                  }}
                >
                  Chat in Queue
                  <span className="liveAgent_inbox_count">
                    {AllData && AllData.chats_in_queue > 0
                      ? AllData.chats_in_queue > 0 &&
                        AllData.chats_in_queue < 10
                        ? '0' + AllData.chats_in_queue
                        : AllData.chats_in_queue
                      : '0'}{' '}
                  </span>
                </TabHeader>
                <TabHeader
                  className={currentTab === 'My' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/my-session/conversations`
                    );
                    setCurrentTab('My');
                  }}
                >
                  My Chats
                  <span className="liveAgent_inbox_count">
                  {AllData && AllData.my_chats > 0
                      ? AllData.my_chats > 0 &&
                        AllData.my_chats < 10
                        ? '0' + AllData.my_chats
                        : AllData.my_chats
                      : '0'}{' '}
                    {/* {mySessionData && mySessionData.total_count > 0
                      ? mySessionData.total_count > 0 &&
                        mySessionData.total_count < 10
                        ? '0' + mySessionData.total_count
                        : mySessionData.total_count
                      : '0'}{' '} */}
                  </span>
                  {/* <sup>1 New</sup> */}
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Open' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/open-sessions/conversations`
                    );
                    setCurrentTab('Open');
                  }}
                >
                  Open
                  <span className="liveAgent_inbox_count">
                  {AllData && AllData.open > 0
                      ? AllData.open > 0 &&
                        AllData.open < 10
                        ? '0' + AllData.open
                        : AllData.open
                      : '0'}{' '}
                    {/* {openSessionData && openSessionData.total_count > 0
                      ? openSessionData.total_count > 0 &&
                        openSessionData.total_count < 10
                        ? '0' + openSessionData.total_count
                        : openSessionData.total_count
                      : '0'}{' '} */}
                  </span>
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Mention' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/mention-sessions/conversations`
                    );
                    setCurrentTab('Mention');
                  }}
                >
                  &#64; Mentions
                  <span className="liveAgent_inbox_count">
                  {AllData && AllData.mentions > 0
                      ? AllData.mentions > 0 &&
                        AllData.mentions < 10
                        ? '0' + AllData.mentions
                        : AllData.mentions
                      : '0'}{' '}
                    {/* {mentionSessionData && mentionSessionData.total_count > 0
                      ? mentionSessionData.total_count > 0 &&
                        mentionSessionData.total_count < 10
                        ? '0' + mentionSessionData.total_count
                        : mentionSessionData.total_count
                      : '0'}{' '} */}
                  </span>
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Closed' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/closed-sessions/conversations`
                    );
                    setCurrentTab('Closed');
                  }}
                >
                  Closed
                  <span className="liveAgent_inbox_count">
                  {AllData && AllData.closed > 0
                      ? AllData.closed > 0 &&
                        AllData.closed < 10
                        ? '0' + AllData.closed
                        : AllData.closed
                      : '0'}{' '}
                    {/* {closedSessionData && closedSessionData.total_count > 0
                      ? closedSessionData.total_count > 0 &&
                        closedSessionData.total_count < 10
                        ? '0' + closedSessionData.total_count
                        : closedSessionData.total_count
                      : '0'}{' '} */}
                  </span>
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Trash' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/trash/conversations`
                    );
                    setCurrentTab('Trash');
                  }}
                >
                  Trash
                  <span className="liveAgent_inbox_count">
                  {AllData && AllData.trash > 0
                      ? AllData.trash > 0 &&
                        AllData.trash < 10
                        ? '0' + AllData.trash
                        : AllData.trash
                      : '0'}{' '}
                  </span>
                </TabHeader>

                <div className="liveagent_online_btn flex">
                  <p>You're {myStatus?.toLowerCase()}</p>
                  {agentID && (
                  <div className="switch_box box_1">
                    <input
                      type="checkbox"
                      className={'switch_1'}
                      checked={myStatus === 'Online'}
                      onChange={() => updateAgentStatus(agentID)}
                    />
                  </div>
                  )}
                </div>
              </TabRow>
            </TabContainer>
          )}
          {/* {tab === 'Settings' && (
            <TabContainer className="liveAgent-subMenu">
              <TabRow className="liveAgent-subMenu-row">
                <TabHeader
                  className={currentTab === 'All' ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab('All');
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/all/conversations`
                    );
                  }}
                >
                  Chat Assignment
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Queue' ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab('Queue');
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/queued-sessions/conversations`
                    );
                  }}
                >
                  Business hours
                </TabHeader>
                <TabHeader
                  className={currentTab === 'My' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/my-session/conversations`
                    );
                    setCurrentTab('My');
                  }}
                >
                  Chat Channels
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Open' ? 'active' : ''}
                  onClick={() => {
                    props.history.push(
                      `/workspace/${props.match.params.workspacename}/live-agent/${selectedBot.id}/open-sessions/conversations`
                    );
                    setCurrentTab('Open');
                  }}
                >
                  SLAs
                </TabHeader>
                <TabHeader
                  className={currentTab === 'Queue' ? 'active' : ''}
                  onClick={() => setCurrentTab('Queue')}
                >
                  Canned Responses
                </TabHeader>
              </TabRow>
            </TabContainer>
          )} */}
          {deletePopup && (
            <Modal className="liveAgent_delete_popup">
              <span className="liveAgent_delete_icon">
                <DeleteIcon />
              </span>
              <h2>Delete Conversation?</h2>
              <Paragraph>
                Conversation will be closed and moved to trash. You will not be
                able to communicate with the user until they start a new
                conversation.
              </Paragraph>{' '}
              <ButtonWrapper>
                <Button type="button" onClick={() => trashConvo()}>
                  <label>Delete</label>
                </Button>
                <Button
                  primary
                  type="button"
                  onClick={() => {
                    setDeletePopup(false);
                    setPageState({
                      action: ActionT.VIEW,
                    });
                    setError(false);
                  }}
                >
                  <label> Cancel</label>
                </Button>
                {error && (
                  <span className="connect_account_failed">
                    <div className="error_input_show error_input_show_add teams_page_alert">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        Something went wrong
                      </span>
                    </div>
                  </span>
                )}
              </ButtonWrapper>
            </Modal>
          )}
          {closePopup && (
            <Modal className="liveAgent_delete_popup">
              {/* <span className="liveAgent_delete_icon">
                <DeleteIcon />
              </span> */}
              <h2>Close Conversation?</h2>
              <Paragraph>
                Conversation will be closed. You will not be able to communicate
                with the user until they start a new conversation.
              </Paragraph>{' '}
              <ButtonWrapper>
                <Button type="button" onClick={() => CloseConvo()}>
                  <label>Ok</label>
                </Button>
                <Button
                  primary
                  type="button"
                  onClick={() => {
                    setClosePopup(false);
                    setPageState({
                      action: ActionT.VIEW,
                    });
                    setError(false);
                  }}
                >
                  <label> Cancel</label>
                </Button>
                {error && (
                  <span className="connect_account_failed">
                    <div className="error_input_show error_input_show_add teams_page_alert">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        Something went wrong
                      </span>
                    </div>
                  </span>
                )}
              </ButtonWrapper>
            </Modal>
          )}
          {permDeletePopup && (
            <Modal className="liveAgent_delete_popup">
              <span className="liveAgent_delete_icon">
                <DeleteIcon />
              </span>
              <h2>Delete Conversation?</h2>
              <Paragraph>
                Deleting a conversation can’t be undone. Please confirm that you
                wish to delete 1 conversation.
              </Paragraph>{' '}
              <ButtonWrapper>
                <Button type="button" onClick={() => permDeleteConvo()}>
                  <label>Delete</label>
                </Button>
                <Button
                  primary
                  type="button"
                  onClick={() => {
                    setError(false);
                    setPermDeletePopup(false);
                    setPageState({
                      action: ActionT.VIEW,
                    });
                  }}
                >
                  <label> Cancel</label>
                </Button>
                {error && (
                  <span className="connect_account_failed">
                    <div className="error_input_show error_input_show_add teams_page_alert">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        Something went wrong
                      </span>
                    </div>
                  </span>
                )}
              </ButtonWrapper>
            </Modal>
          )}
          {restorePopup && (
            <Modal className="liveAgent_delete_popup">
              <span className="liveAgent_delete_icon">
                <AddIcon />
              </span>
              <h2>Restore Conversation</h2>
              <Paragraph>
                Conversation will be restored and moved to closed sessions.
              </Paragraph>{' '}
              <ButtonWrapper>
                <Button type="button" onClick={() => restoreConvo()}>
                  <label>Restore</label>
                </Button>
                <Button
                  primary
                  type="button"
                  onClick={() => {
                    setError(false);
                    setRestorePopup(false);
                    setPageState({
                      action: ActionT.VIEW,
                    });
                  }}
                >
                  <label> Cancel</label>
                </Button>
                {error && (
                  <span className="connect_account_failed">
                    <div className="error_input_show error_input_show_add teams_page_alert">
                      <WarningAlertIcon />
                      <span className="error_menu_hvr">
                        Something went wrong
                      </span>
                    </div>
                  </span>
                )}
              </ButtonWrapper>
            </Modal>
          )}
          {isStatusMutateLoading || isDeleteSessionLoading || isRefetching ? (
            <div>
              <Loader.PageLoader show={true} />
            </div>
          ) : (
            <WebSocketContext.Provider
              value={{
                readyState: readyState,
                message: message,
                handleClickSendMessage: handleClickSendMessage,
                handleCommentSetting: handleCommentSetting,
                handleAttachment: handleAttachment,
                transferAgent: transferAgent,
              }}
            >
              <MessageArray.Provider value={{ messageHistory: messageHistory }}>
                {tab === 'Bot' && (
                  <LiveAgentInbox
                    // Routerprops={props}
                    currentTab={currentTab}
                    // convId={convId}
                    pageState={pageState}
                    setPageState={setPageState}
                    selectedBotID={selectedBot.id}
                    selectConvo={selectConvo}
                    selectMultiConvo={selectMultiConvo}
                    convoRestore={convoRestore}
                    // convData={convData}
                    // setConvData={setConvData}
                    teamData={teamData}
                    bots={bots}
                    workspacename={workspace}
                    linkSelect={linkSelect}
                    status={tabLoc}
                    tabId={tabID}
                    convoDelete={convoDelete}
                    closeConvo={closeConvo}
                    changeURL={changeURL}
                    checkChatID={checkChatID}
                  />
                )}
                {/* {tab === 'Chat History' && (
                  <LiveAgentLogs
                    workspace={workspace}
                    dateOptions={dateOptions}
                    customDates={customDates}
                    currentPage={currentPage}
                    changecurrentPage={changecurrentPage}
                    ChngaeTotalCount={ChngaeTotalCount}
                  />
                )} */}
                {/* {tab === 'DashBoard' && (
                  <LiveAgentSummary workspace={workspace} />
                )} */}
                {/* {tab === 'Settings' && <LiveAgentSettings />} */}
              </MessageArray.Provider>
            </WebSocketContext.Provider>
          )}
        </div>
        {notificationArr && notificationArr.length >0 &&
          <LiveAgentNotification msg={notificationArr} bots ={bots} workspace={workspace} tabBot={tabBot} deleteNotification={deleteNotification}transferAgent ={transferAgent} user={user}/>
        }
        {/* <LiveAgentNotification msg={notificationArr} bots ={bots} workspace={workspace} tabBot={tabBot} deleteNotification={deleteNotification}transferAgent = {transferAgent}/> */}
      </>
    );
  }
}

const useBotsData = (workspace: string, botId: string) => {
  const [selectedBot, setSelectedBot] = useState<BotsLA>(
    botId.toLowerCase() === 'inbox'
      ? inboxBot
      : {
          id: botId,
          name: '',
        }
  );

  const { bots: bData, isBotsFetched } = useBots(workspace);

  useEffect(() => {
    if (isBotsFetched) {
      const bots = bData as BotsLA[];

      if (
        selectedBot.id.toLowerCase() !== 'inbox' ||
        selectedBot.id !== botId
      ) {
        const botToLoad = bots?.find((x) => x.id === botId);
        if (botToLoad) {
          setSelectedBot({
            id: botToLoad.id,
            name: botToLoad.name,
          });
        }
      }
    }
  }, [botId, bData, isBotsFetched]);

  return {
    bData,
    isBotsFetched,
    selectedBot,
    setSelectedBot,
  };
};
